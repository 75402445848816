<template>
  <div
    class="modal fade"
    id="modalListPrice"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="this.formData.kategori != 'man power'"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="modal-header" :style="{ paddingTop: '1rem' }">
            <div class="modal-title">Daftar Harga</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row align-items-center">
              <div class="col-6">
                <div
                  class="form-group d-flex align-items-center"
                  style="margin-bottom: 0px"
                >
                  <label for="jabatan" class="form-label">Barang </label>
                  <Select2
                    style="width: 100%"
                    v-model="formPrice.kode_barang"
                    :options="optionGoods"
                    placeholder="Pilih Barang"
                    @change="changeGoods($event)"
                    @select="changeGoods($event)"
                  />
                </div>
                <div
                  class="text-info"
                  style="font-size: 13px"
                  v-if="onGetPrice"
                >
                  Sedang mengambil harga....
                </div>
              </div>
              <div class="col-6 justify-content-end d-flex">
                <div class="card-total">
                  <div class="total-debit d-flex flex-column mr-0 ml-4">
                    <span>Pagu</span>
                    <span class="ml-auto"
                      >Rp {{ formatMoney(formPrice.price_pagu) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table
                class="table table-bordered table-striped table-hover"
                id="tableListPrice"
              >
                <thead>
                  <tr>
                    <th style="width: 15%">Vendor</th>
                    <th style="width: 15%">Harga</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(value, index) in listGoods"
                    :key="index"
                    v-on:click="setPriceUnit(value)"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <td>{{ value.nama_vendor }}</td>
                    <td class="text-right">{{ formatMoney(value.harga) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button type="submit" class="btn btn-save">Simpan</button>
          </div> -->
        </form>
      </div>
    </div>
  </div>
  <div
    class="modal right fade"
    id="modalAddMember"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Man Power</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Level di Project : </label>
              <Select2
                v-model="levelProject"
                id="levelProject"
                :class="{ 'is-invalid': formErrorAnggota.level }"
                :options="optionLevel"
                placeholder="Pilih Level di Project"
                @change="mySelectEvent($event, 'levelProject')"
                @select="mySelectEvent($event, 'levelProject')"
              />
              <div class="form-error" v-if="formErrorAnggota.level">
                Inputan ini harus anda isi
              </div>
            </div>

            <div class="form-group">
              <label>Fee</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  v-model.lazy="feeLevel"
                  v-money="configMoney"
                  :class="{ 'is-invalid': formErrorAnggota.fee }"
                />
                <div class="input-group-append">
                  <span class="input-group-text">/Orang</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Frekuensi</label>
              <input
                type="text"
                v-model="manPowerFrq"
                placeholder="Masukan frekuensi"
                v-maska="'################'"
                class="form-control"
              />
            </div>
            <hr />

            <div class="d-flex mb-3 justify-content-between">
              <input
                type="text"
                class="form-control"
                v-model="filterKaryawan.search"
                placeholder="Masukkan Nama"
                style="max-width: 200px"
              />

              <Select2
                v-model="filterKaryawan.jabatan"
                :options="optionPosition"
                placeholder="Pilih Jabatan"
                style="width: 150px; max-width: 200px"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <Select2
                v-model="filterKaryawan.divisi"
                :options="optionDepartement"
                placeholder="Pilih Departemen"
                style="width: 175px; max-width: 300px"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />

              <button
                class="btn-add icon-search"
                type="button"
                @click="getKaryawan"
                style="width: 50px; max-width: 100px"
              >
                <img src="/img/icon-search.svg" />
              </button>
            </div>

            <div class="table-responsive">
              <div v-if="KaryawanLoad">
                <Skeletor height="50px" class="mb-2 rounded" />
                <Skeletor height="50px" class="mb-2 rounded" />
                <Skeletor height="50px" class="mb-2 rounded" />
              </div>
              <table v-else class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="width: 10%" class="text-center">
                      <label style="margin-bottom: 0px">
                        <input
                          @change="selectAll"
                          v-model="anggotaSelectAll"
                          value="1"
                          type="checkbox"
                          name="checkbox"
                        />
                      </label>
                    </th>
                    <th style="width: 40%">Nama</th>
                    <th style="width: 30%">Jabatan</th>
                    <th style="width: 30%">Departemen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="listKaryawan.length === 0">
                    <td colspan="4" class="text-center">Tidak ada data</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(value, index) in listKaryawan"
                    :key="index"
                    :class="{ 'd-none': value.is_exist }"
                  >
                    <td class="text-center">
                      <label style="margin-bottom: 0px">
                        <input
                          type="checkbox"
                          v-model="listKaryawan[index].checked"
                          :checked="
                            listKaryawan[index].checked === '1' ? true : false
                          "
                          value="1"
                          name="checkbox"
                        />
                      </label>
                    </td>
                    <td>{{ value.nama }}</td>
                    <td>{{ value.jabatan }}</td>
                    <td>{{ value.divisi }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn-save"
            type="button"
            @click="simpanAnggota"
            style="
              background-color: #40ddd4;
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              padding: 10px;
              border-radius: 5px;
              border: none;
              width: auto;
            "
          >
            Tambah Data
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">
                {{ labelPage }} Item {{ formData.type == "pe" ? "PE" : "RAB" }}
              </h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-project">Project </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailProject',
                        params: { id: id_project },
                      }"
                      >Detail Project
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }} Item
                    {{ formData.type == "pe" ? "PE" : "RAB" }}
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button
                class="btn btn-save w-auto px-2 mr-2"
                :disabled="isSubmit || onTolak || onAcc"
                style="background: #ff5252; border-color: #ff5252"
                type="button"
                @click="setTolak"
                v-if="
                  labelPage == 'Edit' &&
                  isEditable &&
                  checkAccess('project', 'cru_rab_apporval') &&
                  formData.status == 'aktif' &&
                  formData.type != 'pe'
                "
              >
                <span v-if="onTolak" class="fas fa-spinner fa-spin"></span>
                Tolak Item {{ formData.type == "pe" ? "PE" : "RAB" }}
              </button>
              <button
                class="btn btn-save w-auto px-2 mr-2"
                :disabled="isSubmit || onTolak || onAcc"
                style="background: rgb(2, 200, 188) !important"
                type="button"
                @click="setAccRab"
                v-if="
                  labelPage == 'Edit' &&
                  isEditable &&
                  checkAccess('project', 'cru_rab_apporval') &&
                  formData.status == 'aktif' &&
                  formData.type != 'pe'
                "
              >
                <span v-if="onAcc" class="fas fa-spinner fa-spin"></span>
                Setujui Item {{ formData.type == "pe" ? "PE" : "RAB" }}
              </button>
              <button
                class="btn btn-save"
                :disabled="isSubmit || onTolak || onAcc"
                v-if="isEditable && checkAccess('project', 'cru_rab')"
              >
                <span v-if="isSubmit" class="fas fa-spinner fa-spin"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <!-- <div class="mb-3">
              <ul class="nav-custom nav-pills-custom mb-3" v-if="formData.type == 'rab'">
                <li class="nav-item-custom">
                  <a
                    class="nav-link-custom"
                    :class="{
                      active: tabRab == 'data',
                    }"
                    @click="setTabRab('data')"
                    >Detail Item {{ formData.type == "pe" ? "PE" : "RAB" }}</a
                  >
                </li>
                <li class="nav-item-custom">
                  <a
                    :class="{
                      active: tabRab == 'man power',
                    }"
                    @click="setTabRab('man power')"
                    class="nav-link-custom"
                    >Man Power</a
                  >
                </li>
              </ul>
            </div> -->

            <div class="row">
              <div class="col-12">
                <div class="title">
                  Detail Item {{ formData.type == "pe" ? "PE" : "RAB" }}
                </div>
                <div class="sub-title">
                  Edit informasi item
                  {{ formData.type == "pe" ? "PE" : "RAB" }} yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="border-0 row mt-4">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Nama Item</label>
                  <input
                    type="text"
                    v-model="formData.nama"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    @keyup="formChange('nama')"
                    id="nama"
                    :disabled="!isEditable"
                    class="form-control"
                    placeholder="Masukkan nama item"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Parent Item</label>
                  <Select2
                    v-model="formData.id_parent"
                    :class="{
                      'is-invalid': formError && formError.id_parent,
                    }"
                    :disabled="!isEditable"
                    id="id_parent"
                    :options="optionIdParent"
                    :settings="{ templateResult: formatState }"
                    placeholder="Pilih Parent Item"
                    @change="formChange('id_parent', $event)"
                    @select="formChange('id_parent', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_parent"
                  >
                    {{ formError.id_parent }}
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12" v-if="formData.type == 'rab'">
                <div class="form-group">
                  <label>Kategori</label>
                  <Select2
                    v-model="formData.kategori"
                    :class="{ 'is-invalid': formError && formError.kategori }"
                    :disabled="!isEditable || formData.id_parent != ''"
                    id="kategori"
                    :options="optionCategori"
                    placeholder="Pilih Kategori"
                    @change="formChange('kategori')"
                    @select="formChange('kategori')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.kategori"
                  >
                    {{ formError.kategori }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="formData.type == 'rab' && formData.kategori == 'man power' && formData.id_parent"
            >
              <DetailMember
                @showModal="ShowModalAnggota"
                :list="listAnggotaProject"
                :minDate="formProject.tanggal_mulai"
                :maxDate="formProject.tanggal_selesai"
                :isEdit="false"
                @update="updateAnggota($event)"
                @delete="deleteMenPower($event)"
              ></DetailMember>
            </div>

            <div class="row mt-4 align-items-center">
              <div class="col-6">
                <div class="title">
                  Ringkasan {{ formData.type == "pe" ? "PE" : "RAB" }}
                </div>
                <div class="sub-title">
                  Edit informasi item
                  {{ formData.type == "pe" ? "PE" : "RAB" }} yang diperlukan
                </div>
              </div>

              <div class="col-6 text-right">
                <button
                  class="btn btn-add"
                  @click="showModalPrice"
                  type="button"
                  v-if="this.formData.kategori != 'man power'"
                >
                  Daftar Harga
                </button>
              </div>
            </div>
            <hr />
            <div class="border-0 row mt-4">
              <div :class="classItem">
                <div class="form-group">
                  <label>Quantity</label>
                  <div class="input-group">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      v-model="formData.qty"
                      :class="{ 'is-invalid': formError && formError.qty }"
                      @keyup="formChange('qty')"
                      v-maska="'#########'"
                      placeholder="Masukan QTY"
                      class="form-control text-right"
                    />
                    <div
                      class="input-group-append"
                      :style="{ maxWidth: '100px' }"
                    >
                      <input
                        type="text"
                        placeholder="Satuan QTY"
                        v-model="formData.satuan_qty"
                        :class="{
                          'is-invalid': formError && formError.satuan_qty,
                        }"
                        @keyup="formChange('satuan_qty')"
                        class="form-control text-center"
                      />
                    </div>
                  </div>
                  <div class="form-error" v-if="formError && formError.qty">
                    {{ formError.qty }}
                  </div>
                </div>
              </div>
              <div :class="classItem">
                <div class="form-group">
                  <label>Frequency</label>
                  <div class="input-group">
                    <input
                      type="text"
                      v-maska="'#########'"
                      :disabled="!isEditable"
                      v-model="formData.frq_qty"
                      :class="{
                        'is-invalid': formError && formError.frq_qty,
                      }"
                      placeholder="Masukan Frq"
                      @keyup="formChange('frq_qty')"
                      class="form-control text-right"
                    />
                    <div
                      class="input-group-append"
                      :style="{ maxWidth: '100px' }"
                    >
                      <input
                        type="text"
                        placeholder="Satuan Frq"
                        v-model="formData.satuan_frq"
                        :class="{
                          'is-invalid': formError && formError.satuan_frq,
                        }"
                        @keyup="formChange('satuan_frq')"
                        class="form-control text-center"
                      />
                    </div>
                  </div>
                  <div class="form-error" v-if="formError && formError.frq_qty">
                    {{ formError.frq_qty }}
                  </div>
                </div>
              </div>
              <div :class="classItem">
                <div class="form-group">
                  <label>Volume</label>
                  <div class="input-group">
                    <input
                      type="text"
                      v-maska="'#########'"
                      :disabled="!isEditable"
                      v-model="formData.volume"
                      :class="{ 'is-invalid': formError && formError.volume }"
                      placeholder="Masukan Volume"
                      @keyup="formChange('volume')"
                      class="form-control text-right"
                    />
                    <div
                      class="input-group-append"
                      :style="{ maxWidth: '100px' }"
                    >
                      <input
                        type="text"
                        placeholder="Satuan Volume"
                        v-model="formData.satuan_volume"
                        :class="{
                          'is-invalid': formError && formError.satuan_volume,
                        }"
                        @keyup="formChange('satuan_volume')"
                        class="form-control text-center"
                      />
                    </div>
                  </div>
                  <div class="form-error" v-if="formError && formError.frq_qty">
                    {{ formError.frq_qty }}
                  </div>
                </div>
              </div>
              <div :class="classItem">
                <div class="form-group">
                  <label
                    v-if="
                      (formData.status == 'aktif' &&
                        !checkAccess('project', 'cru_rab_apporval')) ||
                      labelPage != 'Edit'
                    "
                    >Unit Cost</label
                  >
                  <label v-else>Unit Cost Yang diajukan</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input
                      v-if="
                        (formData.status == 'aktif' &&
                          !checkAccess('project', 'cru_rab_apporval')) ||
                        labelPage != 'Edit'
                      "
                      type="text"
                      v-model.lazy="formData.unit_cost"
                      :disabled="!isEditable"
                      :class="{
                        'is-invalid': formError && formError.unit_cost,
                      }"
                      v-money="configMoney"
                      @keyup="formChange('unit_cost')"
                      placeholder="Masukan Unit Cost"
                      class="form-control text-right"
                    />
                    <input
                      v-else
                      type="text"
                      v-model.lazy="formData.unit_cost_pengajuan"
                      :disabled="true"
                      :class="{
                        'is-invalid': formError && formError.unit_cost,
                      }"
                      v-money="configMoney"
                      @keyup="formChange('unit_cost')"
                      placeholder="Masukan Unit Cost"
                      class="form-control text-right"
                    />
                  </div>
                  <label
                    v-if="formData.nama_vendor_diajukan || vendorSelected.nama"
                    >Vendor Yang Diajukan :
                    {{
                      formData.nama_vendor_diajukan
                        ? formData.nama_vendor_diajukan
                        : vendorSelected.nama && labelPage != "Edit"
                        ? vendorSelected.nama
                        : "-"
                    }}</label
                  >
                  <div
                    class="form-error"
                    v-if="formError && formError.unit_cost"
                  >
                    {{ formError.unit_cost }}
                  </div>
                </div>
              </div>
              <div
                :class="classItem"
                v-if="
                  labelPage == 'Edit' &&
                  (formData.status == 'acc' ||
                    checkAccess('project', 'cru_rab_apporval'))
                "
              >
                <div class="form-group">
                  <label>Unit Cost Disetujui</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input
                      type="text"
                      v-model.lazy="formData.unit_cost"
                      :disabled="!isEditable"
                      :class="{
                        'is-invalid': formError && formError.unit_cost,
                      }"
                      v-money="configMoney"
                      @keyup="formChange('unit_cost')"
                      placeholder="Masukan Unit Cost"
                      class="form-control text-right"
                    />
                  </div>
                  <label
                    v-if="formData.nama_vendor_disetujui || vendorSelected.nama"
                    >Vendor Yang Disetujui :
                    {{
                      formData.nama_vendor_disetujui
                        ? formData.nama_vendor_disetujui
                        : vendorSelected.nama
                        ? vendorSelected.nama
                        : "-"
                    }}</label
                  >
                  <div
                    class="form-error"
                    v-if="formError && formError.unit_cost"
                  >
                    {{ formError.unit_cost }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <div class="d-flex justify-content-between form-group mb-0">
                    <label>Subtotal</label>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        v-model="formData.include_pph"
                        class="custom-control-input"
                        @change="formChange('include_pph')"
                        id="includePPh"
                        value="true"
                      />
                      <label class="custom-control-label" for="includePPh"
                        >Include PPh</label
                      >
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input
                      type="text"
                      v-model.lazy="formData.sub_total"
                      readonly="true"
                      placeholder="Subtotal"
                      v-money="configMoney"
                      :disabled="!isEditable"
                      :class="{
                        'is-invalid': formError && formError.sub_total,
                      }"
                      class="form-control text-right"
                    />
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.sub_total"
                  >
                    {{ formError.sub_total }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>PPh</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input
                      type="text"
                      v-model.lazy="formData.pph"
                      @keyup="formChange('pph')"
                      :class="{ 'is-invalid': formError && formError.pph }"
                      v-money="configMoney"
                      placeholder="Masukkan PPh"
                      class="form-control text-right"
                      :disabled="formData.include_pph || !isEditable"
                    />
                  </div>
                  <div class="form-error" v-if="formError && formError.pph">
                    {{ formError.pph }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-12">
                <div class="form-group">
                  <label>Remarks</label>
                  <textarea
                    type="text"
                    v-model="formData.remarks"
                    id="remarks"
                    class="form-control"
                    :disabled="!isEditable"
                    @keyup="formChange('remarks')"
                    placeholder="Masukkan catatan"
                  ></textarea>
                  <div class="form-error" v-if="formError && formError.remarks">
                    {{ formError.remarks }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import NavbarDashboard from "../../../components/Navbar.vue";
import SidebarDashboard from "../../../components/Sidebar.vue";
import DetailMember from "./Component/DetailMember.vue";
// import Datepicker from 'vue3-date-time-picker';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import { maska } from "maska";
import { VMoney } from "v-money";
import moment from "moment";
import "moment/locale/id";
import {
  get_ProjectRabDetail,
  get_ProjectRabListParent,
  post_ProjectRabAcc,
  post_ProjectRabSave,
} from "../../../actions/project/rab";
import {
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../helper";

// import {
//     ref
// } from 'vue';
import $ from "jquery";
import { get_BarangList, get_BarangPriceList } from "../../../actions/barang";
import { get_KaryawanList } from "../../../actions/karyawan";
import { get_ProjectLevelList } from "../../../actions/project/level";
import { get_DepartementList } from "../../../actions/company/departement";
import { get_ListPosition } from "../../../actions/company/posistion";
import { get_ProjectDetail } from "../../../actions/project";

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    DetailMember,
    // Datepicker
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      myValue: "aaaa",
      labelPage: this.$route.params.nomor ? "Edit" : "Tambah",
      nomor: this.$route.params.nomor ? this.$route.params.nomor : "",
      id_project: this.$route.params.kode,
      id_company: cksClient().get("_account").id_company,
      optionIdParent: [],
      optionLevel: [],
      optionCategori: [
        {
          id: "man power",
          text: "Man Power",
        },
        {
          id: "vendor",
          text: "Vendor",
        },
        {
          id: "operasional",
          text: "Operasional",
        },
      ],
      formData: {
        id: this.$route.params.nomor ? this.$route.params.nomor : "",
        id_company: cksClient().get("_account").id_company,
        kode_project: this.$route.params.kode,
        nama: "",
        kategori: "",
        qty: 1,
        satuan_qty: "Pax",
        frq_qty: 1,
        satuan_frq: "Event",
        volume: 0,
        satuan_volume: "",
        unit_cost: "0",
        sub_total: 0,
        include_pph: true,
        pph: "",
        remarks: "",
        id_parent: "",
        sub_level: 0,
        status: "aktif",
        unit_cost_pengajuan: 0,
        kode_barang: "",
        id_vendor_diajukan: "",
        nama_vendor_diajukan: "",
        id_vendor_disetujui: "",
        nama_vendor_disetujui: "",
        type: this.$route.params.type ? this.$route.params.type : "rab",
      },
      vendorSelected: {
        id: "",
        nama: "",
      },
      onAcc: false,
      onTolak: false,
      isSubmit: false,
      formError: [],
      isEditable: true,
      rules: {
        nama: {
          required: true,
        },
        qty: {
          required: false,
        },
        frq_qty: {
          required: false,
        },
        satuan_qty: {
          required: false,
        },
        satuan_frq: {
          required: false,
        },
        unit_cost: {
          required: false,
        },
        sub_total: {
          required: false,
        },
        include_pph: {
          required: false,
        },
        pph: {
          required: false,
        },
        remarks: {
          required: false,
        },
        id_parent: {
          required: false,
        },
      },
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: "",
        masked: false /* doesn't work with directive */,
      },
      classItem: "col-md-4 col-sm-6 col-12",
      // vendorItem
      priceTable: "",
      optionGoods: [],
      listGoods: [],
      onGetPrice: false,
      formPrice: {
        kode_barang: "",
        price_pagu: 0,
      },
      tabRab: "data",
      //anggota
      totalFeeSame: true,
      filterKaryawan: {
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        aktif: true,
        divisi: "all",
        jabatan: "all",
        search: "",
        with_freelance: "ya",
      },
      formErrorAnggota: {
        karyawan: false,
        level: false,
      },
      KaryawanLoad: false,
      listKaryawan: [],
      anggotaSelectAll: "",
      listAnggotaProject: [],
      levelProject: "",
      manPowerFrq: "1",
      feeLevel: 0,
      listKategori: [],
      deleteAnggota: [],
      allAnggotaProject: [],
      formProject: {
        tanggal_mulai: "",
        tanggal_selesai: "",
      },
    };
  },

  created() {
    if (this.formData.type == "pe") {
      localStorage.setItem("ItemPe", true);
    } else {
      localStorage.setItem("ItemRab", true);
      this.getProject();
      this.getLevel();
      this.getPosition();
      this.getDepartement();
    }
    this.getParent();
    this.classUpdate();
    this.getGoods();
    if (this.nomor) {
      this.getData();
    }
    // console.log(
    //   cksClient().get("_access"),
    //   this.checkAccess("project", "cru_rab_apporval")
    // );
  },

  mounted() {
    this.setTablePrice();
  },

  beforeUnmount() {
    if (this.priceTable) {
      this.priceTable.destroy();
      this.priceTable = "";
    }
  },

  methods: {
    classUpdate() {
      this.classItem = "col-md-4 col-sm-6 col-12";
      if (
        (this.checkAccess("project", "cru_rab_apporval") ||
          this.formData.status == "acc") &&
        this.labelPage == "Edit"
      ) {
        this.classItem = "col-md-4 col-sm-6 col-12";
      }
    },
    showModalPrice() {
      $("#modalListPrice").modal("show");
    },
    getProject() {
      get_ProjectDetail(this.formData.kode_project, (res) => {
        const { data, anggota } = res;
        this.formProject.tanggal_mulai = data.tanggal_mulai;
        this.formProject.tanggal_selesai = data.tanggal_selesai;
        for (const key in anggota) {
          if (Object.hasOwnProperty.call(anggota, key)) {
            this.allAnggotaProject.push({
              id: anggota[key].id_karyawan,
              text: anggota[key].nama,
            });
          }
        }
      });
    },
    getData() {
      get_ProjectRabDetail(
        this.nomor,
        (res) => {
          var { data, anggota } = res;
          if (data && data.id) {
            delete data.created_at;
            delete data.updated_at;
            data.include_pph = data.include_pph == "true" ? true : false;
            data.unit_cost = this.formatMoney(data.unit_cost);
            data.pph = this.formatMoney(data.pph);
            delete data.acc_at;
            if (!data.id_vendor_disetujui) {
              data.id_vendor_disetujui = data.id_vendor_diajukan;
              data.nama_vendor_disetujui = data.nama_vendor_diajukan;
            }
            this.formData = { ...this.formData, ...data };
            this.isEditable = res.editable;
            if (!data.unit_cost_pengajuan && data.status != "acc") {
              this.formData.unit_cost_pengajuan = data.unit_cost;
            }
            this.formPrice.kode_barang = data.kode_barang;
            this.listAnggotaProject = anggota;
            for (const key in this.listAnggotaProject) {
              if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
                const element = this.listAnggotaProject[key];
                this.listAnggotaProject[key].jadwal = element.jadwal
                  ? element.jadwal
                      .split("|")
                      .map((value) => moment(value).format("YYYY-MM-DD"))
                  : [];
              }
            }

            setTimeout(() => {
              if (data.kode_barang) {
                this.getPrice();
              }
            }, 500);
            this.classUpdate();
            this.countAll();
          } else {
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Data tidak ditemukan",
              onSubmit: () => {
                localStorage.setItem("ItemRab", true);
                this.$router.push({
                  name: "DetailProject",
                  params: {
                    id: this.id_project,
                  },
                });
              },
            });
          }
        },
        (err) => {
          console.log(err);
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            onSubmit: () => {
              localStorage.setItem("ItemRab", true);
              this.$router.push({
                name: "DetailProject",
                params: {
                  id: this.id_project,
                },
              });
            },
          });
        }
      );
    },

    getGoods() {
      get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
          isAll: true,
        },
        (res) => {
          var { list } = res;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const el = list[key];
              this.optionGoods.push({
                id: el.kode,
                text: el.nama,
                harga_pagu: el.harga_pagu ? el.harga_pagu : 0,
              });
            }
          }
        }
      );
    },

    changeGoods() {
      // this.formPrice.price_pagu = val.harga_pagu;
      this.getPrice();
    },

    setTablePrice() {
      if (this.priceTable) {
        this.priceTable.destroy();
        this.priceTable = "";
      }
      setTimeout(() => {
        this.priceTable = $("#tableListPrice").DataTable({
          info: false,
          search: true,
          pageLength: 20,
          language: {
            emptyTable: "Tidak ada data",
          },
          initComplete: () => {
            $(".dataTables_filter").addClass("d-block");
            $(".dataTables_filter").addClass("mb-3");
          },
        });
      }, 600);
    },

    getPrice() {
      this.listGoods = [];
      this.onGetPrice = true;
      if (this.priceTable) {
        this.priceTable.destroy();
        this.priceTable = "";
      }
      get_BarangPriceList(
        this.formPrice.kode_barang,
        (res) => {
          this.onGetPrice = false;
          const { list, harga_pagu } = res;
          this.formPrice.price_pagu = harga_pagu;
          this.listGoods = list;
          this.setTablePrice();
        },
        () => {
          this.onGetPrice = true;
        }
      );
    },

    setPriceUnit(value) {
      if (
        this.labelPage === "Edit" &&
        this.formData.status == "aktif" &&
        this.checkAccess("project", "cru_rab_apporval")
      ) {
        this.formData.id_vendor_disetujui = value.id;
        this.formData.nama_vendor_disetujui = value.nama_vendor;
      }
      this.vendorSelected.id = value.id;
      this.vendorSelected.nama = value.nama_vendor;
      this.formData.unit_cost = value.harga;
      $("#modalListPrice").modal("hide");
      this.countAll();
    },

    getParent() {
      get_ProjectRabListParent(this.id_project, this.formData.type, (res) => {
        this.optionIdParent = res.list;
      });
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      if (state.id === this.formData.id && this.formData.id != "") {
        return null;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $('<div class="' + padding + '">' + state.text + "</div>");
      return $state;
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async formChange(key, val) {
      if (Object.hasOwnProperty.call(this.rules, key)) {
        this.rules[key].changed = true;
      }
      if (key === "id_parent" && this.formData.id_parent) {
        this.formData.kategori = val.kategori;
        // var rule = {
        //     qty: {
        //         required: true
        //     },
        //     frq_qty: {
        //         required: true
        //     },
        //     unit_cost: {
        //         required: true
        //     },
        //     sub_total: {
        //         required: true
        //     }
        // }
        // this.rules = {...this.rules, ...rule}
      } else if (key === "id_parent" && !this.formData.id_parent) {
        // var rulea = {
        //     qty: {
        //         required: false
        //     },
        //     frq_qty: {
        //         required: false
        //     },
        //     unit_cost: {
        //         required: false
        //     },
        //     sub_total: {
        //         required: false
        //     }
        // }
        // this.rules = {...this.rules, ...rulea}
      }
      this.countAll();
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    countAll() {
      var qty = this.formData.qty;
      var frq_qty = this.formData.frq_qty;
      var unit_cost = this.formData.unit_cost
        ? parseInt(this.formData.unit_cost.toString().split(".").join(""))
        : 0;
      var pph = this.formData.pph
        ? parseInt(this.formData.pph.toString().split(".").join(""))
        : 0;

      var volume = this.formData.volume
        ? parseInt(this.formData.volume.toString().split(".").join(""))
        : 0;
      var sub_total = qty * unit_cost;
      if (frq_qty) {
        sub_total = sub_total * frq_qty;
      }

      if (volume) {
        sub_total = sub_total * volume;
      }

      if (!this.formData.include_pph) {
        sub_total += pph;
      }
      this.formData.sub_total = this.formatMoney(sub_total);
      this.countFeeAndSubtotal();
    },
    postData() {
      this.isSubmit = true;
      var data = { ...this.formData };

      data.include_pph = this.formData.include_pph ? "true" : "false";
      data.kode_barang = this.formPrice.kode_barang;
      if (data.id) {
        if (this.vendorSelected.id && data.status == "aktif") {
          data.id_vendor_diajukan = this.vendorSelected.id;
        } else if (this.vendorSelected.id && data.status == "acc") {
          data.id_vendor_disetujui = this.vendorSelected.id;
        }
      } else {
        if (this.vendorSelected.id) {
          data.id_vendor_diajukan = this.vendorSelected.id;
        }
      }

      data.anggota = this.listAnggotaProject;
      data.deleteAnggota = this.deleteAnggota;

      post_ProjectRabSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? `Item ${
                  this.formData.type == "pe" ? "PE" : "RAB"
                } berhasil dicatat`
              : `Item ${
                  this.formData.type == "pe" ? "PE" : "RAB"
                } berhasil diperbarui`;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              localStorage.setItem("ItemRab", true);
              this.$router.push({
                name: "DetailProject",
                params: {
                  id: this.id_project,
                },
              });
            },
            onDismiss: () => {
              localStorage.setItem("ItemRab", true);
              this.$router.push({
                name: "DetailProject",
                params: {
                  id: this.id_project,
                },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      var checkManPower = true;
      if (
        this.formData.kategori == "man power" &&
        this.listAnggotaProject.length == 0
      ) {
        checkManPower = false;
      }
      if (check.success && this.totalFeeSame) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (this.totalFeeSame === false) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Total Fee Man Power tidak boleh melebihi nilai dari item rab",
            showCancelButton: true,
            showConfirmButton: false,
          });
        } else if (checkManPower === false) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Silakan tambah data man power telebih dahulu",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      }
    },

    async setAccRab() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menyetujui item rab ini?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Saya setuju",
          onSubmit: () => {
            this.onAcc = true;
            // this.formData.id_vendor_disetujui = this.vendorSelected.id;
            post_ProjectRabAcc(
              this.formData,
              () => {
                this.onAcc = false;
                this.formData.status = "acc";
                showAlert(this.$swal, {
                  title: "BERHASIL",
                  msg: "Item RAB berhasil disetujui",
                  onSubmit: () => {
                    localStorage.setItem("ItemRab", true);
                    this.$router.push({
                      name: "DetailProject",
                      params: {
                        id: this.id_project,
                      },
                    });
                  },
                });
                this.isNeededAcc = false;
              },
              () => {
                this.onAcc = false;
                showAlert(this.$swal, {
                  title: "GAGAL",
                  msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
              }
            );
          },
        });
      }
    },

    async setTolak() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin akan menolak item rab ini?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.onTolak = true;
          var data = { ...this.formData };
          data.status = "tidak";
          // this.formData.id_vendor_disetujui = this.vendorSelected.id;
          post_ProjectRabAcc(
            data,
            () => {
              this.onTolak = false;
              this.formData.status = "tidak";
              showAlert(this.$swal, {
                title: "BERHASIL",
                msg: "Item RAB berhasil ditolak",
                onSubmit: () => {
                  localStorage.setItem("ItemRab", true);
                  this.$router.push({
                    name: "DetailProject",
                    params: {
                      id: this.id_project,
                    },
                  });
                },
                onDismiss: () => {
                  localStorage.setItem("ItemRab", true);
                  this.$router.push({
                    name: "DetailProject",
                    params: {
                      id: this.id_project,
                    },
                  });
                },
              });
              this.isNeededAcc = false;
            },
            () => {
              this.onAcc = false;
              showAlert(this.$swal, {
                title: "GAGAL",
                msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
                showCancelButton: true,
                showConfirmButton: false,
              });
            }
          );
        },
      });
    },

    setTabRab(tab) {
      if (this.tabRab != tab) {
        this.tabRab = tab;
      }
    },
    //ANGGOTA
    getLevel() {
      get_ProjectLevelList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionLevel = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionLevel.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getPosition() {
      get_ListPosition(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionPosition = [
            {
              id: "all",
              text: "Semua Jabatan",
            },
          ];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionPosition.push({
                id: element.id,
                text: element.nama,
              });
            }
          }

          this.optionPosition.push({
            id: "Freelance",
            text: "Freelance",
          });
        }
      );
    },
    getDepartement() {
      get_DepartementList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionDepartement = [
            {
              id: "all",
              text: "Semua Departemen",
            },
          ];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionDepartement.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
          this.optionDepartementWithoutAll = this.optionDepartement;
          this.optionDepartementWithoutAll.splice(0, 1);
        }
      );
    },
    async getKaryawan() {
      this.filterKaryawan.id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      this.KaryawanLoad = true;
      this.filterKaryawan.aktif = true;
      get_KaryawanList(this.filterKaryawan, async (res) => {
        this.KaryawanLoad = false;
        var { list } = res;
        this.listKaryawan = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            list[key].checked = false;
            var check = this.checkAnggota(list[key].id);
            list[key].is_exist = check;
          }
        }
        this.listKaryawan = list;
      });
    },
    ShowModalAnggota() {
      this.levelProject = "";
      this.feeLevel = "";
      this.manPowerFrq = "1";
      this.filterKaryawan.divisi = "all";
      this.filterKaryawan.jabatan = "all";
      this.filterKaryawan.search = "";
      $("#modalAddMember").modal("show");
      this.getKaryawan();
    },
    selectAll() {
      for (const key in this.listKaryawan) {
        if (
          Object.hasOwnProperty.call(this.listKaryawan, key) &&
          this.anggotaSelectAll
        ) {
          this.listKaryawan[key].checked = !this.listKaryawan[key].is_exist
            ? "1"
            : "";
        } else {
          this.listKaryawan[key].checked = "";
        }
      }
    },
    simpanAnggota() {
      var level = $("#levelProject")
        .find(`option[value="${this.levelProject}"]`)
        .text();
      var fee = parseInt(this.feeLevel.toString().split(".").join(""));
      if (level && fee > 0) {
        this.formErrorAnggota.level = false;
        var check = true;
        for (const key in this.listKaryawan) {
          if (Object.hasOwnProperty.call(this.listKaryawan, key)) {
            const element = this.listKaryawan[key];
            if (element.checked) {
              check = true;
              this.listAnggotaProject.push({
                id_karyawan: element.id,
                id_level: this.levelProject,
                level: $("#levelProject")
                  .find(`option[value="${this.levelProject}"]`)
                  .text(),
                nama: element.nama,
                in_payroll: 'tidak',
                jadwal: [],
                fee: fee,
                seen: false,
                frq: this.manPowerFrq ? this.manPowerFrq : "1",
              });
            }
          }
        }
        if (check) {
          this.formErrorAnggota.karyawan = false;
          this.updateAnggota(this.listAnggotaProject);
          $("#modalAddMember").modal("hide");
        } else {
          this.formErrorAnggota.karyawan = true;
        }
      } else {
        if (!level) {
          this.formErrorAnggota.level = true;
        }
        if (!fee) {
          this.formErrorAnggota.fee = true;
        }
      }
    },
    deleteMenPower(el) {
      this.deleteAnggota.push(el);
    },
    checkAnggota(id) {
      for (const key in this.allAnggotaProject) {
        if (Object.hasOwnProperty.call(this.allAnggotaProject, key)) {
          const element = this.allAnggotaProject[key];
          if (element.id === id) {
            return true;
          }
        }
      }
      return false;
    },
    updateAnggota(ev) {
      this.listAnggotaProject = ev;
      var selectedAnggota = [];
      for (const key in this.listAnggotaProject) {
        if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
          const element = this.listAnggotaProject[key];
          var check = this.allAnggotaProject.findIndex(
            (e) => e.id == element.id_karyawan
          );
          selectedAnggota.push(element.id_karyawan);
          if (check < 0) {
            this.allAnggotaProject.push({
              id: element.id_karyawan,
              text: element.nama,
            });
          }
        }
      }
      this.allAnggotaProject = this.allAnggotaProject.filter((item) => selectedAnggota.includes(item.id));
      this.countFeeAndSubtotal();
    },
    countFeeAndSubtotal() {
      var fee = 0;
      for (const key in this.listAnggotaProject) {
        if (Object.hasOwnProperty.call(this.listAnggotaProject, key)) {
          const element = this.listAnggotaProject[key];
          fee += element.fee * element.frq;
        }
      }
      var subTotal = this.formData.sub_total ? parseInt(this.formData.sub_total.split(".").join("")) : 0;
      this.totalFeeSame = false;
      if (fee <= subTotal) {
        this.totalFeeSame = true;
      }

      if (this.formData.type == "pe") {
        this.totalFeeSame = false;
      }
    },
    mySelectEvent(key) {
      console.log(key);
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.modal-body .form-group:last-child {
  position: relative;
}

.title-item {
  position: relative;
}

.card-modal {
  padding: 20px 36px;
}

.btn-add-title {
  background-color: #d7ffea;
  color: #3cb778;
  font-weight: 500;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #eceff1;
}

.table td,
.table th {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: center;
}

.title-item {
  background-color: #eceff1;
}

.text {
  max-width: 300px;
  width: 150px;
}

.title-rab {
  background-color: #2e3a59;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-add {
  padding: 10px 10px;
  margin-left: 20px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 500px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.modal-content {
  overflow-y: auto;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.modal-dialog {
  max-width: 800px;
}

.total-debit {
  line-height: 20px;
}

.table td,
.table th {
  padding: 10px 26px !important;
}

.nav-custom {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-pills-custom .nav-link-custom {
  position: relative;
  color: #000;
  display: block;
  padding: 0.5rem 15px;
  text-decoration: none;
  cursor: pointer;
}

.nav-pills-custom .nav-item-custom .nav-link-custom.active {
  color: #2e3a59;
  background-color: transparent;
  border-radius: 0px;
  text-align: center;
}

.nav-pills-custom .nav-item-custom .nav-link-custom.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 5px;
  background: #68caf8;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 54px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 6px solid #68caf8;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.nav-pills-custom .nav-item-custom .nav-link-custom:hover {
  background-color: #f6f7fe;
}
</style>
