<template>
  <div class="row mt-3">
    <div class="col-6">
      <div class="title">Detail Man Power Project</div>
      <div class="sub-title">Edit informasi yang diperlukan</div>
    </div>
    <div class="col-6 align-self-center text-right">
      <div>
        <div
          class="btn-add d-inline-block"
          style="cursor: pointer"
          v-if="isEditable"
          @click="addAanggota"
        >
          + Tambah Man Power
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="">
    <div class="alert alert-danger mb-3">
      <h6>
        <i
          class="fas fa-exclamation-triangle"
          style="font-size: 20px; margin-right: 10px"
        ></i>
        PERHATIAN!
      </h6>
      Silakan atur man power untuk item RAB ini di Aplikasi RAWUH
    </div>
    <table class="table table-bordered table-striped" id="tableMember">
      <thead>
        <tr>
          <th style="width: 15%">Nama</th>
          <th style="width: 20%">Level di Project</th>
          <th style="width: 10%">Fee</th>
          <th style="width: 15%">Jadwal On Site</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in listMember" :key="index">
          <td>{{ value.nama }}</td>
          <td>{{ value.level }}</td>
          <td class="text-right">
            {{ value.fee ? formatMoney(value.fee * value.frq) : "-" }}
          </td>
          <td class="position-relative">
            <div class="multiple-date pt-2">
              <div class="row">
                <div
                  class="tag-date"
                  v-for="(item, k) in value.jadwal"
                  :key="k"
                >
                  {{ convertDate(item, index) }}
                </div>
              </div>
            </div>
            <img
              v-if="isEditable"
              src="/img/icon-date.svg"
              class="icon-date"
              @click="showPicker(index)"
            />
            <Calendar
              :attributes="attributes(index)"
              @dayclick="onDayClick($event, index, value.frq)"
              :min-date="dateMin"
              :max-date="dateMax"
              v-click-outside="showPicker"
              :masks="masks"
              v-if="listMember[index].seen"
              class="datePicker"
            >
            </Calendar>
            <img
              v-if="isEditable && value.in_payroll == 'tidak'"
              src="/img/avatar/icon-delete.svg"
              @click="deleteAnggota(index)"
              style="
                position: absolute;
                right: -39px;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                width: 20px;
              "
              title="Hapus Sub Judul"
            />
            <!-- <Datepicker :autoApply="true"  v-model="date1" v-if="seen" id="hide" class="datePicker" /> -->
          </td>
        </tr>
        <tr v-if="listMember.length === 0">
          <td colspan="4" class="text-center">Tidak ada data</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import Select2 from 'vue3-select2-component';
// import Datepicker from 'vue3-date-time-picker';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import {
  Calendar,
  // DatePicker
} from "v-calendar";
import { ref } from "vue";
import vClickOutside from "click-outside-vue3";

export default {
  name: "DetailMember",
  components: {
    // DatePicker,
    Calendar,
  },

  props: {
    list: Object,
    minDate: String,
    maxDate: String,
    isEdit: Boolean,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  // computed: {

  // },

  data() {
    return {
      listMember: this.$props.list,
      dateMin: this.$props.minDate
        ? moment(this.$props.minDate).format("YYYY-MM-DD")
        : "",
      dateMax: this.$props.maxDate
        ? moment(this.$props.maxDate).format("YYYY-MM-DD")
        : "",
      days: [],
      date1: [],
      seen: false,
      masks: {
        input: "YYYY-MM-DD h:mm A",
      },
      dataTable: "",
      isEditable: this.$props.isEdit,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  methods: {
    dates(index) {
      // var dates = this.listMember[index].jadwal.filter(value => {
      //     let returnData = true;
      //     if(this.dateMin && moment(value).unix() < moment(this.dateMin).unix()){
      //         returnData = false
      //     }
      //     if (this.dateMax && moment(value).unix() > moment(this.dateMax).unix()) {
      //         returnData = false
      //     }
      //     return returnData;
      // })
      return this.listMember[index].jadwal;
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    attributes(index) {
      return this.dates(index).map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    onDayClick(ev, index, frq) {
      const idx = this.listMember[index].jadwal.findIndex(
        (d) => d === moment(ev.date).format("YYYY-MM-DD")
      );
      if (idx >= 0) {
        this.listMember[index].jadwal.splice(idx, 1);
      } else if (this.listMember[index].jadwal.length < parseInt(frq)) {
        this.listMember[index].jadwal.push(
          moment(ev.date).format("YYYY-MM-DD")
        );
      }
      this.listMember[index].jadwal.sort(
        (a, b) => moment(a).unix() - moment(b).unix()
      );
      this.$emit("update", this.listMember);
    },
    addAanggota() {
      this.$emit("showModal");
    },
    showPicker(index = "") {
      for (const key in this.listMember) {
        if (Object.hasOwnProperty.call(this.listMember, key)) {
          if (index == key) {
            this.listMember[key].seen = !this.listMember[key].seen;
          } else {
            this.listMember[key].seen = false;
          }
        }
      }
    },
    deleteAnggota(index) {
      this.$emit("delete", this.listMember[index].id_karyawan);
      this.listMember.splice(index, 1);
      this.$emit("update", this.listMember);
    },
  },

  watch: {
    list: function (newVal) {
      this.listMember = newVal;
    },
    minDate: function (newVal) {
      this.dateMin = newVal ? moment(newVal).format("YYYY-MM-DD") : "";
    },
    maxDate: function (newVal) {
      this.dateMax = newVal ? moment(newVal).format("YYYY-MM-DD") : "";
    },
    isEdit: function (newVal) {
      this.isEditable = newVal;
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

/* .btn-add {
        padding: 10px 10px;
        margin-left: 20px;
    } */

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #d0d0d0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"]:checked {
  background-color: #68caf8 !important;
  border: none;
}

.modal-content {
  overflow-y: auto;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding-right: 40px;
}

.modal-body {
  padding-bottom: 60px;
}

.icon-date {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}

.datePicker {
  z-index: 300;
  right: 50px;
  top: 50px;
}

table > tbody > tr > td:last-child {
  position: relative;
}

.datePicker {
  position: absolute;
}

.multiple-date .row {
  padding: 0px 40px 0px 22px;
}

.tag-date {
  background-color: #68caf8;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 5px 10px;
}
</style>
